
export default {
  components: {
    TitlePage: () => import(`@/components/TitlePage`),
    RoomSelected: () => import("@/components/RoomSelected"),
  },
  data() {
    return {
      lang: "th",
      title: null,
      type: null,
      imgBanner: null,
      amenities: null,
      detail: null,
      img: null,
    };
  },
  metaInfo () {
    return {
      title: this.$router.currentRoute.params.name,
      titleTemplate: '%s | Kaewsamui resort',
      meta: [
        { name: 'description', content: 'Warm hotel, comfortable like at home. Located in Muang Suratthani Surrounded by the natural atmosphere of Khao Tha Phet' },
        { property: 'og:title', content: this.$router.currentRoute.params.name },
        { property: 'og:site_name', content: 'Kaewsamui resort' },
        { property: 'og:type', content: 'website' },    
        { name: 'robots', content: 'index,follow' }
      ]
    }
  },
  mounted() {
    // let { name } = this.$router.currentRoute.params;
    // this.getRoomdetail(name);
    // this.title = name.replace(/-/g, " ");
    // console.log(this.title);
  },
  watch: {
    $route: {
      immediate: true,
      handler: function(newValue) {
          let { name } = this.$router.currentRoute.params;
          this.lang = newValue.params.lang;
          // console.log(this.lang)
          // console.log(newValue, oldValue);
          this.getRoomdetail(name);
      },
    }
  },
  methods: {
    async getRoomdetail(tags) {
      const { results } = await this.$prismic.client.query([
        this.$prismic.Predicates.at("document.type", "room_detail"),
        this.$prismic.Predicates.at("document.tags", [this.lang, tags]),
      ]);
      console.log(tags, results);
      this.title = results[0].data.title;
      this.type = results[0].data.type;
      this.imgBanner = results[0].data.banner_img;
      // this.amenities = results[0].data.list_amenities;
      this.amenities = results[0].data.img_list_amenities;
      this.detail = results[0].data.detail;
      this.img = results[0].data.list;
    },
  },
};
